<template>
    <div class="news_child_div">
        <div class="nav_div"></div>
        <div class="news_content_div allContentWidth">
            <div class="return_box">
                <span @click="backTopPage"><< 返回新闻活动列表</span>
            </div>
            <div class="news_content_box">
                <div class="news_title">
                    <p class="news_title_span">{{ contentObj.title }}</p>
                    <p class="news_time_span">{{ contentObj.time }}</p>
                </div>
                <div class="new_content">
                    <p v-for="a in contentObj.contentArr" :key="a.id"
                       :style=" a.bold?'font-weight:bold;':'font-weight:normal;'">
                        {{ a.text }}
                    </p>
                </div>
                <div class="news_img_div">
                    <div v-for="a in contentObj.img_url">
                        <img v-if="a.height" :style="'height:'+a.height" :src="a.url"/>
                        <img v-if="!a.height" :src="a.url"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {
        this.initData();
    },
    watch: {},
    methods: {
        backTopPage() {
            this.$router.push(`/news`);
        },
        initData() {
            this.contentAll.company.map((item) => {
                if (item.key === Number(this.$route.params.id)) {
                    this.contentObj = item;
                }
            })
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        return {
            imgSrc: imgSrc,
            contentObj: {},
            contentAll: {
                'industry': [
                    {id: 1, content: '关于出口舱单新规的通知', time: '2018.12.01'},
                ],
                'company': [
                    {
                        key: 1,
                        title: '东志集团上海分公司为员工举办生日会',
                        time: '2022.06.24',
                        contentArr: [
                            {
                                id: 101,
                                text: '疫情拦路，爱不止步。本季度生日会场景唯美，糕点丰富，给大家带来了特别的生日祝福。这个季度的生日聚会很精彩，有各种各样的蛋糕。'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_birthday.jpg')}]
                    },
                    {
                        key: 2,
                        title: '东志集团上海分公司荣获CEIV认证',
                        time: '2022.03.03',
                        contentArr: [
                            {
                                id: 101,
                                text: '热烈庆祝东志国际货物运输代理有限公司上海分公司 荣获国际航空运输协会 （IATA）药品物流独立验证卓越中心CEIV Pharma认证。'
                            },
                            {
                                id: 102,
                                text: '药品运输因其特殊的安全性和时效性，在各项操作环节中都需执行严格的标准。CEIV Pharma作为全球官方药品物流操作体系，是对承运公司运输资质的国际权威认证。东志集团将秉承着客户至上，使命必达的企业精神；开拓创新,砥砺前行，致力于为全球客户提供更优质的服务。'
                            }
                        ],
                        img_url: [{url: require('../../assets/img/1551655072440_.pic.png')}]
                    },
                    {
                        key: 3,
                        title: '东志集团上海分公司八月生日会现场',
                        time: '2022.08.26',
                        contentArr: [
                            {
                                id: 101,
                                text: '这个八月，热浪席卷，高温持续时间已破历史纪录。为了给员工在这炎炎夏日带来丝丝凉意，这个月的生日会，我们特意选择了降暑的冰淇淋蛋糕，还自制了冰饮。祝八月生日的所有员工，生日快乐，心想事成。'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_birthday_new1.png')}]
                    },
                    {
                        key: 4,
                        title: 'Eastrong 马来西亚公司推出本地仓储运输服务',
                        time: '2023.01',
                        contentArr: [
                            {id: 101, text: '除了进出马来西亚的海运和空运，我们还可以为您提供其他服务：'},
                            {id: 102, text: '• 报关'},
                            {id: 103, text: '• 仓储'},
                            {id: 104, text: '• 卡车运输和全国送货'},
                            {id: 105, text: '• 跨境运输（中国、缅甸、老挝、柬埔寨、泰国和新加坡）'},
                            {id: 106, text: '• 快递服务'},
                            {id: 107, text: '• 项目货物'},
                            {
                                id: 108,
                                text: '如您有整箱和拼箱货物从您的起运地运往马来西亚，我们在巴生港拥有 50,000 平方英尺的仓库，以支持大部分电子商务业务，包括自海外到马来西亚的运输服务（马来西亚半岛内）。 \n' +
                                    'Eastrong 马来西亚能够提供本地运输（1-5吨）卡车用于马来西亚半岛内的本地运输，以支持大多数电子商务和中小型企业（Small & Medium Enterprise）业务。'
                            },
                        ],
                        img_url: [
                            {url: require('../../assets/img/home_bnews4_1.png')},
                            {url: require('../../assets/img/home_bnews4_2.png')},
                            {url: require('../../assets/img/home_bnews4_3.png')}
                        ]
                    },
                    {
                        key: 5,
                        title: '东志集团获得CCLP（CargoWise Certified Logistics Provider）认证',
                        time: '2023.01',
                        contentArr: [
                            {id: 101, text: '东志集团于2022年1月获得Cargowise系统CCLP认证。'},
                            {
                                id: 102,
                                text: 'CargoWise Certified Logistics Provider（CCLP）项目是专为持续提升员工数据系统使用能力的Cargowise用户设计的认证计划。'
                            },
                            {id: 103, text: '推动业务增长', bold: true},
                            {
                                id: 104,
                                text: 'CargoWise 认证用户是知识渊博的内部专家，可以依靠他们帮助您实施数字化转型计划。'
                            },
                            {id: 105, text: '提高性能', bold: true},
                            {
                                id: 106,
                                text: '获得认证后，团队成员可以更好地理解系统，帮助他们更快地解决问题，并提供更好的客户服务。'
                            },
                            {id: 107, text: '更快部署', bold: true},
                            {
                                id: 108,
                                text: '凭借成熟的项目经验和业务流程知识，拥有 CargoWise 认证用户的公司可以实现更顺畅的部署。'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_bnews5_1.png'), height: '200px;'}]
                    },
                    {
                        key: 6,
                        title: '2023年东志集团销售会议',
                        time: '2023.05',
                        contentArr: [
                            {
                                id: 101,
                                text: '2023.3.17-3.18在胡志明举办了2023年东志集团年度销售会议，会议总结了2022年的工作不足并对2023年提出更新的要求。'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_bnews6_1.jpeg')}]
                    },
                    {
                        key: 7,
                        title: '2023年东志团建之成都篇',
                        time: '2023.05',
                        contentArr: [
                            {
                                id: 101,
                                text: '为更好的激发员工的工作激情，树立员工之间积极沟通、相互信任，增强责任感及归属感，公司于2023.4.21-4.23开展了成都团建活动。'
                            },
                        ],
                        img_url: [{url: require('../../assets/img/home_bnews7_1.jpeg')}]
                    },
                    // {
                    //     key: 8,
                    //     title: '热烈祝贺东志国际货物运输代理有限公司台湾分公司成立',
                    //     time: '2023.09',
                    //     contentArr: [
                    //         {
                    //             id: 102,
                    //             text: '热烈祝贺东志国际货物运输代理有限公司台湾分公司成立'
                    //         }
                    //     ],
                    //     img_url: [{url: require('../../assets/img/taiwan-gs1.jpg')}, {url: require('../../assets/img/taiwan-gs2.jpg')}]
                    // },
                    {
                        key: 9,
                        title: '2024年东志集团年度会议',
                        time: '2024.02',
                        contentArr: [
                            {
                                id: 103,
                                text: '2024.1.4-1.5在中国上海举办了2024年东志集团年度会议，总结回顾了2023年的工作，分析了当前形势和任务，安排部署了2024年的工作。'
                            }
                        ],
                        img_url: [{url: require('../../assets/img/2024nh1.jpg')}, {url: require('../../assets/img/2024nh2.jpg')}, {url: require('../../assets/img/2024nh3.jpg')}]
                    }
                ],
            }
        }
    }
}
</script>
<style lang="less">
.news_child_div {
  .news_content_div {
    .return_box {
      margin: 50px 0 50px 0;
      cursor: pointer;

      span {
        font-size: 14px;
      }
    }

    .news_content_box {
      .news_title {
        text-align: center;
        margin: 30px 0 30px 0;

        .news_title_span {
          font-size: 20px;
          font-weight: 700;
        }

        .news_time_span {
          font-size: 16px;
        }
      }

      .new_content {
        line-height: 58px;
        width: 80%;
        margin: 0 auto;
      }

      .news_img_div {
        width: 100%;
        margin: 30px auto 40px;

        div {
          margin-bottom: 10px;
          text-align: center;

          img {
            height: 350px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .news_child_div {
    .news_content_div {
      padding: 0 15px;

      .return_box {
        padding-left: 0px;
      }

      .news_content_box {
        .news_img_div {
          width: 100%;
          height: auto;

          div {
            img {
              height: auto !important;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
